import { Button, ModalBody, ModalTitle, ModalHeader } from "@withjuly/solis";
import { usePitchQueue } from "~/utils/context/pitch-queue";
import { formatOxfordCompanyList } from "@withjuly/fabric";
import { useDeleteMatches } from "~/utils/api/query/matches";

interface ConfirmDeleteModalProps {
	setIsConfirmDeleteOpen: (isOpen: boolean) => void;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
	setIsConfirmDeleteOpen,
}) => {
	const { brands } = usePitchQueue();
	const deleteMatches = useDeleteMatches();

	return (
		<>
			<ModalHeader>
				<ModalTitle>
					Delete {brands.length} match{brands.length > 1 ? "es" : ""}?
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<p className="text-gray-100">
					{formatOxfordCompanyList(brands.map((brand) => brand.company.name))}{" "}
					will be removed from Discover.
				</p>
				<div className="flex w-full gap-3 pt-4">
					<Button
						variant="secondary"
						size="small"
						onClick={() => setIsConfirmDeleteOpen(false)}
						full
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						size="small"
						onClick={() => {
							const matchUuids: string[] = [];
							brands.forEach((brand) => {
								if (brand.match) {
									matchUuids.push(brand.match.uuid);
								}
							});
							deleteMatches.mutate(
								{
									uuids: matchUuids.map((uuid) => {
										return { type: "match", data: uuid };
									}),
								},
								{
									onSuccess: () => setIsConfirmDeleteOpen(false),
								},
							);
						}}
						isLoading={deleteMatches.isLoading}
						full
					>
						Delete
					</Button>
				</div>
			</ModalBody>
		</>
	);
};
