import { INDUSTRIES } from "@withjuly/fabric";
import { useFormContext } from "react-hook-form";
import { ToggleGroupInput } from "~/components/Input/ToggleGroupInput";

export interface ContentIndustryInputProps {
	max?: number;
	isCsv?: boolean;
	center?: boolean;
	hideLabel?: boolean;
	name: string;
}

export const ContentIndustryInput: React.FC<ContentIndustryInputProps> = ({
	max = 5,
	isCsv,
	center,
	hideLabel,
	name,
}) => {
	const { watch } = useFormContext();
	const selectedValues = (watch(name) as string[] | undefined) ?? [];

	return (
		<ToggleGroupInput
			isCsv={isCsv}
			hideLabel={hideLabel}
			center={center}
			label="Content Industries"
			name={name}
			items={INDUSTRIES.map((industry) => ({
				value: industry.name,
				disabled:
					selectedValues.length >= max &&
					!selectedValues.includes(industry.name),
				children: industry.name,
			}))}
		/>
	);
};
