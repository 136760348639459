import { Search } from "@withjuly/julycons";
import { cx } from "@withjuly/frontend-common";
import {
	GetBrandBookCompaniesForm,
	PLANS,
	SubscriptionPlan,
} from "@withjuly/fabric";
import { trpc } from "../Utility/trpc";
import { useEffect, useMemo, useState } from "react";
import { Loader, TextInput, Toggle } from "@withjuly/solis";
import { BrandCard } from "./BrandCard";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useDebounce } from "~/utils/hooks/debounce";
import { SkeletonBrandCard } from "./SkeletonBrandCard";

const INDUSTRIES = [
	{ name: "All", emoji: "🎉" },
	{ name: "July Partner Brands", emoji: "💰" },
	{ name: "Fashion", emoji: "👖" },
	{ name: "Beauty", emoji: "💄" },
	{ name: "Food", emoji: "🍕" },
	{ name: "Home", emoji: "🏠" },
	{ name: "Health", emoji: "🩺" },
	{ name: "Parenthood", emoji: "👶" },
	{ name: "Tech", emoji: "🖥" },
	{ name: "Fitness", emoji: "🏃" },
	{ name: "Pets", emoji: "🐶" },
	{ name: "Education", emoji: "📚" },
	{ name: "Toys", emoji: "🧸" },
	{ name: "Travel", emoji: "✈" },
	{ name: "Business", emoji: "📈" },
	{ name: "Automotive", emoji: "🚗" },
	{ name: "Outdoors", emoji: "🏔" },
	{ name: "Art", emoji: "🎨" },
	{ name: "Entertainment", emoji: "🍿" },
	{ name: "Gaming", emoji: "🕹" },
	{ name: "Music", emoji: "🎸" },
	{ name: "Sports", emoji: "⚽" },
];

export const BrandBook = () => {
	const [companySearch, setCompanySearch] = useState("");
	const { value: debouncedSearchValue, isDebouncing } =
		useDebounce(companySearch);
	const [filterByVerifiedEmails, setFilterByVerifiedEmails] = useState(false);
	const [industry, setIndustry] = useState<string>("all");
	const [highestCompletedAnimation, setHighestCompletedAnimation] = useState(0);
	const { ref, inView } = useInView();
	const { data: billing } = trpc.billing.get.useQuery();
	const hasBrandBookAccess = useMemo(() => {
		const plan = billing ? PLANS[billing.plan as SubscriptionPlan] : undefined;
		return (plan?.order ?? 0) >= PLANS.trending.order;
	}, [billing]);

	const search: GetBrandBookCompaniesForm = useMemo(() => {
		return {
			name: debouncedSearchValue,
			industry,
			verifiedEmail: filterByVerifiedEmails,
		};
	}, [debouncedSearchValue, filterByVerifiedEmails, industry]);

	const { data, fetchNextPage, isFetchingNextPage, isLoading } =
		trpc.match.getCompanies.useInfiniteQuery(search, {
			refetchOnWindowFocus: false,
			getNextPageParam: (data) => {
				return data.nextCursor;
			},
		});

	const brands = useMemo(() => {
		if (data) {
			return data?.pages
				.flatMap((brands) => brands.brands)
				.map((brand, index) => {
					const log = Math.log(index + 0.25 - highestCompletedAnimation);
					const delay = Math.max(0.5 * log, 0.2);
					const duration = log * 0.05 + 0.2;
					return (
						<motion.div
							key={`${brand.company.uuid}-${index}`}
							variants={{
								hidden: {
									opacity: 0,
								},
								visible: {
									opacity: 1,
									transition: {
										duration,
										delay,
										ease: "easeInOut",
									},
								},
							}}
							initial={
								index <= highestCompletedAnimation ? "visible" : "hidden"
							}
							animate="visible"
							onAnimationComplete={() =>
								setHighestCompletedAnimation((prev) => Math.max(prev, index))
							}
						>
							<BrandCard
								brand={brand}
								isBrandBook={true}
								canPitch={hasBrandBookAccess}
								lastPitched={brand.lastPitched}
							/>
						</motion.div>
					);
				});
		} else {
			return null;
		}
	}, [data, hasBrandBookAccess, highestCompletedAnimation]);

	useEffect(() => {
		setHighestCompletedAnimation(() => 0);
	}, [search]);

	useEffect(() => {
		if (inView && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, inView, isFetchingNextPage]);

	return (
		<div className="relative flex flex-col">
			<div className="flex h-[122px] w-full flex-col justify-center gap-4 border-b border-b-gray-500 bg-gray-800 py-4">
				<div className="flex w-full items-center justify-between px-6">
					<div className="flex w-full items-center gap-3">
						<div className="w-full max-w-[344px]">
							<TextInput
								name="name"
								size="small"
								placeholder="Search by brand or product type"
								leftIcon={<Search />}
								value={companySearch}
								onChange={(e) => setCompanySearch(e.target.value)}
							/>
						</div>
						{data?.pages[0]?.count !== undefined ? (
							<>
								<p className="hidden md:block">
									Showing {data?.pages[0]?.count} brands
								</p>
								<p className="block md:hidden">{data?.pages[0]?.count}</p>
							</>
						) : null}
					</div>
					<div className="flex w-full items-center justify-end">
						<div>
							<Toggle
								label={
									<>
										<p className="hidden md:block">
											Only show verified contact emails
										</p>
										<p className="block md:hidden">Verified emails</p>
									</>
								}
								checked={filterByVerifiedEmails}
								setChecked={setFilterByVerifiedEmails}
							/>
						</div>
					</div>
				</div>
				<div className="flex gap-2 overflow-x-scroll">
					{INDUSTRIES.map(({ name, emoji }, index) => {
						return (
							<Tag
								key={`${name}-${index}`}
								value={name.toLowerCase()}
								title={name}
								emoji={emoji}
								isSelected={name.toLocaleLowerCase() === industry}
								setIndustry={setIndustry}
							/>
						);
					})}
				</div>
			</div>
			{/* NO RESULTS */}
			{data?.pages[0]?.count === 0 ? (
				<div className="flex h-[calc(100vh-72px-122px-64px)] w-full flex-col items-center justify-center gap-4 md:h-[calc(100vh-72px-122px)]">
					<div className="shadow-brand-glow rounded-lg border border-blue-400 bg-blue-700 p-2">
						<Search className="h-4 w-4" />
					</div>
					<h5>No Results</h5>
				</div>
			) : null}

			{(!data && isLoading) || isDebouncing ? (
				<div className="flex h-[calc(100vh-72px-122px-64px)] w-full flex-col items-center justify-center gap-4 md:h-[calc(100vh-72px-122px)]">
					<Loader />
				</div>
			) : null}

			{/* BRANDS */}
			{brands ? (
				<div className="relative grid h-full max-h-[calc(100vh-72px-122px-64px)] grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 overflow-y-scroll px-6 py-8 md:max-h-[calc(100vh-72px-122px)] md:grid-cols-[repeat(auto-fill,minmax(320px,1fr))]">
					{brands}
					{brands.length !== 0 && brands.length !== data?.pages[0]?.count ? (
						<>
							<SkeletonBrandCard />
							<SkeletonBrandCard />
							<SkeletonBrandCard />
							<SkeletonBrandCard />
							<SkeletonBrandCard />
						</>
					) : null}
					{ref ? <div ref={ref} /> : null}
				</div>
			) : null}
		</div>
	);
};

interface TagProps {
	value: string;
	title: string;
	emoji: string;
	isSelected?: boolean;
	setIndustry: React.Dispatch<React.SetStateAction<string>>;
}

const Tag: React.FC<TagProps> = ({
	value,
	title,
	emoji,
	isSelected,
	setIndustry,
}) => {
	return (
		<button
			onClick={() => setIndustry(() => value)}
			className={cx(
				"flex items-center justify-center gap-2 rounded-lg border border-transparent bg-gray-500 px-4 py-2 transition-colors first:ml-6 last:mr-6 hover:bg-gray-400",
				isSelected &&
					"border-blue-500 bg-blue-800 text-blue-200 hover:bg-blue-700",
			)}
		>
			<p>{emoji}</p>
			<p className="whitespace-nowrap">{title}</p>
		</button>
	);
};
