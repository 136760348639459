import { ArrowRight, Close } from "@withjuly/julycons";
import { ModalTitle, Button } from "@withjuly/solis";
import DiscoverInfoWebp from "~/assets/DiscoverInfo.webp";

interface DiscoverInfoModalProps {
	setIsOpen: (isOpen: boolean) => void;
}

export const DiscoverInfoModal: React.FC<DiscoverInfoModalProps> = ({
	setIsOpen,
}) => {
	return (
		<div className="flex w-full items-center gap-8 p-8">
			<img
				className="hidden w-[162px] md:block"
				src={DiscoverInfoWebp.src}
				alt=""
			/>
			<div className="flex w-full flex-col gap-6">
				<div className="flex w-full items-center justify-between">
					<ModalTitle className="text-h4 font-agrandir">Discover</ModalTitle>
					<Button
						size="icon-small"
						variant="icon"
						aria-label="Close modal"
						onClick={() => setIsOpen(false)}
					>
						<Close />
					</Button>
				</div>

				<p className="text-body-lg text-white-100">
					On the Discover page, you are matched with brands based on your
					audience and content categories. <br />
					<br />
					From here, you can learn more about specific brands, and pitch the
					ones you&apos;re interested in. Messages are sent directly from your
					email, so brands are more likely to respond.
				</p>
				<a
					className="flex items-center gap-1 underline transition-colors hover:text-blue-300"
					href="#"
				>
					<p className="text-body-lg">Learn more about Discover</p>{" "}
					<ArrowRight className="text-xs" />
				</a>
			</div>
		</div>
	);
};
