import { Brand } from "@withjuly/fabric";
import { BrandCard } from "./BrandCard";
import { MatchesInfoCard } from "./MatchesInfoCard";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocalStorage } from "~/utils/hooks/localstorage";
import { DealOnBoardCard } from "./DealOnBoardCard";

export interface MatchGridProps {
	brands: Brand[];
	totalMatches?: number;
	inViewRef?: (node?: Element | null | undefined) => void;
	isWishlist?: boolean;
}

export const MatchGrid: React.FC<MatchGridProps> = ({
	brands,
	totalMatches,
	inViewRef,
	isWishlist = false,
}) => {
	const [highestCompletedAnimation, setHighestCompletedAnimation] = useState(0);
	const [onBoardStep, setOnBoardStep] = useLocalStorage<
		| {
				step: string;
				uuid: string;
		  }
		| undefined
	>("onboard", undefined);
	const [showDealFlowCard, setShowDealFlowCard] = useState(
		onBoardStep && onBoardStep.step === "dealflow",
	);

	useEffect(() => {
		if (onBoardStep && onBoardStep.step === "dealflow") {
			setShowDealFlowCard(() => true);
		}
	}, [onBoardStep]);

	return (
		<div className="w-full">
			<div className="grid h-full grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 md:grid-cols-[repeat(auto-fill,minmax(320px,1fr))]">
				{showDealFlowCard ? (
					<motion.div
						variants={{
							hidden: {
								opacity: 0,
							},
							visible: {
								opacity: 1,
								transition: {
									duration: 0.25,
									ease: "easeInOut",
								},
							},
						}}
						initial="hidden"
						animate="visible"
					>
						<DealOnBoardCard
							hideCard={() => setShowDealFlowCard(() => false)}
						/>
					</motion.div>
				) : (
					<MatchesInfoCard isWishlist={isWishlist} matchCount={totalMatches} />
				)}

				{brands.map((match, i) => {
					const log = Math.log(i + 0.25 - highestCompletedAnimation);
					const delay = Math.max(0.5 * log, 0.2);
					const duration = log * 0.05 + 0.2;

					return (
						<motion.div
							key={i}
							variants={{
								hidden: {
									opacity: 0,
								},
								visible: {
									opacity: 1,
									transition: {
										duration,
										delay,
										ease: "easeInOut",
									},
								},
							}}
							initial="hidden"
							animate="visible"
							onAnimationComplete={() =>
								setHighestCompletedAnimation((prev) => Math.max(prev, i))
							}
						>
							<BrandCard
								isWishlist={isWishlist}
								brand={match}
								onBoardStep={onBoardStep}
								setOnBoardStep={setOnBoardStep}
							/>
						</motion.div>
					);
				})}

				{inViewRef ? (
					<div ref={inViewRef} style={{ visibility: "hidden" }} />
				) : null}
			</div>
		</div>
	);
};
