import React, { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputWrapper, Toggle } from "@withjuly/solis";

export interface SwitchInputProps {
	name: string;
	label: string | React.ReactElement;
	disabled?: boolean;
	onChange?: () => void;
}

export const Switch: React.FC<SwitchInputProps> = ({
	name,
	label,
	disabled,
	onChange,
}) => {
	const { control } = useFormContext();
	const id = useId();

	return (
		<FormInputWrapper name={name} id={id}>
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<Toggle
						id={name}
						label={label}
						checked={field.value}
						setChecked={(checked) => {
							field.onChange(checked);
							onChange?.();
						}}
						disabled={disabled}
						{...field}
					/>
				)}
			/>
		</FormInputWrapper>
	);
};
