import { useEffect } from "react";
import { trpc } from "~/components/Utility/trpc";
import { usePitchQueue } from "../context/pitch-queue";

export const usePruneQueue = () => {
	const { data: allQueueable } = trpc.match.queueable.useQuery();
	const { brands, pruneQueue } = usePitchQueue();

	useEffect(() => {
		if (allQueueable) {
			let queueHasStaleMatches = false;
			brands.forEach((brand) => {
				if (!queueHasStaleMatches) {
					queueHasStaleMatches = !allQueueable.some(
						(queuable) =>
							queuable.match && queuable.match.uuid === brand.match?.uuid,
					);
				}
			});

			if (queueHasStaleMatches) {
				const uuids: string[] = [];
				allQueueable.forEach((queuable) => {
					if (queuable.match) {
						uuids.push(queuable.match.uuid);
					}
				});

				pruneQueue(uuids);
			}
		}
	}, [allQueueable, brands, pruneQueue]);
};
