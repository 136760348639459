import React from "react";
import { Close } from "@withjuly/julycons";
import { Button } from "@withjuly/solis";
import { useRouter } from "next/router";

interface DealOnBoardCardProps {
	hideCard: () => void;
}

export const DealOnBoardCard: React.FC<DealOnBoardCardProps> = ({
	hideCard,
}) => {
	const router = useRouter();
	return (
		<div className="shadow-60 flex h-[25rem] flex-col justify-between rounded-xl border border-dashed border-gray-300 bg-gray-800 p-6 sm:h-[30rem]">
			<div className="flex flex-col gap-3">
				<div className="flex w-full items-start justify-between">
					<div className="text-h4 flex items-center gap-2">
						<h4 className="text-h4">
							Pitch moved to <br /> Dealflow 📥
						</h4>
						<span></span>
					</div>
					<button className="cursor-pointer" onClick={hideCard}>
						<Close />
					</button>
				</div>
				<p>
					Once you send a pitch, the message is moved to your Dealflow board.
				</p>
			</div>
			<Button
				className="h-[42px]"
				onClick={() => {
					router.push("/dealflow");
				}}
			>
				Check it out
			</Button>
		</div>
	);
};
