import { AllMatches, BrandBook } from "~/components/Matches";
import { ReactElement, useState } from "react";
import { AppLayout } from "~/layout/AppLayout";
import { NextPageWithLayout } from "~/utils/next";
import { useCreator } from "~/utils/context/creator";
import { Loader } from "@withjuly/solis";
import { CreatorAppLayout } from "~/layout/CreatorAppLayout";
import { MatchActions } from "~/components/Matches/MatchActions/MatchActions";
import * as Tabs from "@radix-ui/react-tabs";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { BillingProvider } from "~/utils/context/billing";
import { cx } from "@withjuly/frontend-common";
import { Lightning } from "@withjuly/julycons";

enum Page {
	FOR_YOU = "for_you",
	BRAND_BOOK = "brand_book",
}

const DiscoverDashboard: NextPageWithLayout = () => {
	const { creatorProfile } = useCreator();
	const isBrandBookEnabled = useFeatureFlagEnabled("brand-book");
	const [currentPage, setCurrentPage] = useState<Page>(() => Page.FOR_YOU);

	if (!creatorProfile) {
		return (
			<div className="flex h-full w-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	return (
		<Tabs.Root
			className="relative flex h-full max-h-[calc(100vh-64px)] flex-col md:max-h-[100vh]"
			defaultValue={Page.FOR_YOU}
			onValueChange={(value) => setCurrentPage(() => value as Page)}
		>
			<div className="left-0 z-30 flex h-[72px] w-full items-center justify-between border-b border-b-gray-500 bg-gray-900 px-6">
				<Tabs.List className="flex items-center justify-center gap-2">
					<DiscoverTab title="For You" value={Page.FOR_YOU} />
					{isBrandBookEnabled ? (
						<DiscoverTab title="Brand Book" value={Page.BRAND_BOOK} />
					) : null}
				</Tabs.List>
				<MatchActions />
			</div>

			<div
				className={cx(
					"z-20 hidden h-11 w-full items-center justify-center gap-2 bg-blue-800 text-blue-100",
					creatorProfile?.isAutomaticPitchingEnabled &&
						currentPage === Page.FOR_YOU &&
						"flex",
				)}
			>
				<Lightning className="h-[14px] w-[14px]" />
				<p>Automatic Pitching is on</p>
			</div>
			<Tabs.Content
				value={Page.FOR_YOU}
				className="h-screen overflow-scroll px-6"
			>
				<AllMatches />
			</Tabs.Content>
			<Tabs.Content value={Page.BRAND_BOOK} className="h-screen overflow-auto">
				<BrandBook />
			</Tabs.Content>
		</Tabs.Root>
	);
};

interface DiscoverTabProps {
	title: string;
	value: Page;
}

const DiscoverTab: React.FC<DiscoverTabProps> = ({ title, value }) => {
	return (
		<Tabs.TabsTrigger
			value={value}
			className="whitespace-nobreak data-[state=active]:text-white-100 data-[state=active]:shadow-brand-glow-small w-fit rounded-lg border border-transparent px-3 py-2 text-gray-200 transition-all data-[state=active]:border-blue-400 data-[state=active]:bg-blue-800"
		>
			<p>{title}</p>
		</Tabs.TabsTrigger>
	);
};

DiscoverDashboard.getLayout = (page: ReactElement) => {
	return (
		<CreatorAppLayout>
			<BillingProvider>
				<AppLayout>{page}</AppLayout>
			</BillingProvider>
		</CreatorAppLayout>
	);
};

export default DiscoverDashboard;
