import { EmailQueue, EmailQueueStatusSchema } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Check, Clock } from "@withjuly/julycons";
import {
	Button,
	Loader,
	ModalBody,
	ModalHeader,
	ModalTitle,
	Progress,
} from "@withjuly/solis";
import { useEffect, useState } from "react";
import { trpc } from "~/components/Utility/trpc";

export const QueueProgressModal: React.FC = () => {
	const [expandedQueue, setExpandedQueue] = useState<string>();
	const { data: queues, isLoading } = trpc.dealFlow.getQueuedPitches.useQuery(
		undefined,
		{
			// Poll every 90 seconds to update as email are sent
			refetchInterval: 90 * 1000,
		},
	);

	useEffect(() => {
		if (queues && queues.length > 0) {
			setExpandedQueue(queues[0]?.uuid);
		}
	}, [queues]);

	return (
		<>
			<ModalHeader>
				<ModalTitle className="text-h4 font-agrandir">Sending Queue</ModalTitle>
			</ModalHeader>
			<ModalBody className="flex flex-col gap-6">
				<div
					className={cx(
						"flex w-full flex-col rounded-lg bg-gray-800",
						isLoading && "min-h-[96px] items-center justify-center",
					)}
				>
					{isLoading ? (
						<Loader />
					) : (
						queues?.map((queue, i) => (
							<QueueProgress
								key={queue.uuid}
								queue={queue}
								index={i}
								expanded={queue.uuid === expandedQueue}
								setExpandedQueue={setExpandedQueue}
							/>
						))
					)}
				</div>
			</ModalBody>
		</>
	);
};

interface QueueProgressProps {
	queue: EmailQueue;
	index: number;
	expanded: boolean;
	setExpandedQueue: (uuid: string) => void;
}

const QueueProgress: React.FC<QueueProgressProps> = ({
	queue,
	index,
	expanded,
	setExpandedQueue,
}) => {
	const utils = trpc.useContext();
	const cancelQueue = trpc.dealFlow.cancelQueue.useMutation({
		onMutate: () => {
			const previousQueues = utils.dealFlow.getQueuedPitches.getData();

			if (!previousQueues) {
				return;
			}

			utils.dealFlow.getQueuedPitches.setData(undefined, () => {
				return previousQueues.filter((q) => q.uuid !== queue.uuid);
			});
		},
		onSuccess: () => {
			utils.dealFlow.getQueuedPitches.invalidate();
			utils.match.paginated.invalidate();
			utils.match.wishlist.invalidate();
		},
	});

	const numCompletedItems = queue.items.reduce((acc, item) => {
		if (item.status === EmailQueueStatusSchema.enum.COMPLETED) {
			return acc + 1;
		}

		return acc;
	}, 0);

	return (
		<div className="border-t border-t-gray-500">
			<div
				className="flex w-full flex-col gap-3 border-b border-b-gray-500 p-6 transition-colors hover:bg-gray-700"
				role="button"
				aria-label={`show items in queue ${index + 1}`}
				onClick={() => setExpandedQueue(queue.uuid)}
			>
				<div className="flex w-full items-center justify-between">
					<p>Queue {index + 1}</p>
					<div className="flex items-center gap-3">
						<p className="text-bold-lg text-white-100">
							{queue.status === EmailQueueStatusSchema.enum.IN_PROGRESS
								? `${numCompletedItems} of ${queue.items.length} emails sent`
								: "Waiting to send"}
						</p>
						{queue.status === EmailQueueStatusSchema.enum.IN_PROGRESS ? (
							<Button
								variant="danger"
								size="micro"
								onClick={(e) => {
									e.stopPropagation();
									cancelQueue.mutate(queue.uuid);
								}}
							>
								Stop
							</Button>
						) : (
							<Button
								variant="secondary"
								size="micro"
								className="h-[24px] w-[58px]"
								onClick={(e) => {
									e.stopPropagation();
									cancelQueue.mutate(queue.uuid);
								}}
							>
								Cancel
							</Button>
						)}
					</div>
				</div>
				<Progress value={(numCompletedItems / queue.items.length) * 100} />
			</div>
			{expanded ? (
				<div className="flex w-full flex-col gap-4 px-6 py-4">
					{queue.items.map((item) => (
						<div
							key={item.uuid}
							className="flex w-full items-center justify-between"
						>
							<p
								className={
									item.status === EmailQueueStatusSchema.enum.COMPLETED
										? "text-body-lg text-gray-200"
										: ""
								}
							>
								{item.companyName}
							</p>
							{item.status === EmailQueueStatusSchema.enum.PENDING ? (
								<Clock />
							) : (
								<Check className="text-blue-400" />
							)}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};
