import {
	FormInputWrapper,
	ToggleGroup,
	ToggleGroupItem,
	ToggleGroupItemProps,
	useFormWrapper,
} from "@withjuly/solis";
import { useId } from "react";

export interface ToggleGroupInputProps {
	className?: string;
	name: string;
	label?: string;
	items: ToggleGroupItemProps[];
	disabled?: boolean;
	isCsv?: boolean;
	center?: boolean;
	hideLabel?: boolean;
}

export const ToggleGroupInput: React.FC<ToggleGroupInputProps> = ({
	className,
	name,
	label,
	hideLabel = false,
	items,
	isCsv,
	center,
	...rest
}) => {
	const id = useId();
	const { register, setValue, value } = useFormWrapper(name);
	const form = register(name);

	return (
		<FormInputWrapper id={id} label={hideLabel ? undefined : label} name={name}>
			<ToggleGroup
				className={className}
				center={center}
				value={
					isCsv
						? value?.split(",").filter((i: string) => i !== "") ?? []
						: value
				}
				setValue={(value) => {
					if (isCsv) {
						setValue(value?.filter((i) => i !== "").join(",") ?? "");
					} else {
						setValue(value);
					}
				}}
				{...rest}
				{...form}
			>
				{items.map((item) => (
					<ToggleGroupItem key={item.value} {...item} />
				))}
			</ToggleGroup>
		</FormInputWrapper>
	);
};
