import {
	CreateWishlistItemSchema,
	getStatusDisplayName,
	WishlistRequestStatus,
} from "@withjuly/fabric";
import {
	ModalDescription,
	ModalBody,
	ModalHeader,
	ModalTitle,
	Button,
	StatusIndicator,
	Heading,
	Text,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@withjuly/solis";
import { FormProvider } from "react-hook-form";
import { TextInput } from "../Input";
import { Delete, Info } from "@withjuly/julycons";
import { trpc } from "../Utility/trpc";
import { useZodForm } from "~/utils/hooks/zod-form";
import { useCreator } from "~/utils/context/creator";

export const WishlistModal: React.FC = () => {
	const { creatorProfile } = useCreator();
	const utils = trpc.useContext();
	const deleteRequestMutation = trpc.wishlist.delete.useMutation({
		onSuccess: () => utils.wishlist.allRequests.invalidate(),
	});
	const createWishlistMutation = trpc.wishlist.request.useMutation({
		onSuccess: () => {
			utils.wishlist.allRequests.invalidate();
			// Also invalidate user to update credits
			utils.user.current.invalidate();
			utils.creator.getManagedCreators.invalidate();
		},
	});
	const { data: wishlistRequests } = trpc.wishlist.allRequests.useQuery();

	const form = useZodForm({
		schema: CreateWishlistItemSchema,
		values: {
			companyName: "",
			companyHandle: "",
		},
		submit: async (data, { onFormError }) => {
			createWishlistMutation.mutate(data, {
				onSuccess: () => form.reset(),
				onError: onFormError,
			});
		},
	});

	const renderWishlistStatus = (
		status: WishlistRequestStatus,
		comment?: string,
		hasTemporary?: boolean,
	) => {
		if (status === WishlistRequestStatus.RECEIVED) {
			return <StatusIndicator status="blue" label="Requested" />;
		} else if (status === WishlistRequestStatus.UNRESPONSIVE) {
			return (
				<StatusIndicator
					status="red"
					label={
						<div className="flex items-center gap-1">
							<Text>No Response</Text>
							{comment && (
								<Tooltip>
									<TooltipTrigger asChild>
										<span>
											<Info />
										</span>
									</TooltipTrigger>
									<TooltipContent>{comment}</TooltipContent>
								</Tooltip>
							)}
						</div>
					}
				/>
			);
		} else if (status === WishlistRequestStatus.FULFILLED) {
			return (
				<StatusIndicator status="green" label={getStatusDisplayName(status)} />
			);
		} else if (hasTemporary) {
			return <StatusIndicator status="yellow" label="Needs More Time" />;
		}

		return <Text>Unknown</Text>;
	};

	const creditsLeft = creatorProfile?.companyWishlistCredits;

	return (
		<>
			<ModalHeader>
				<ModalTitle>Company Wishlist</ModalTitle>
				<ModalDescription>
					Request companies you aren’t matched with, and our team will work to
					connect you with them.
				</ModalDescription>
			</ModalHeader>
			<ModalBody>
				<div className="flex flex-col gap-6">
					<FormProvider {...form}>
						<form
							id="wishlist-form"
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
							}}
							onSubmit={form.onSubmit}
						>
							<div className="flex flex-col items-start gap-2">
								<TextInput
									name="companyName"
									label="Company Name"
									placeholder="Company Name"
								/>
								<TextInput
									name="companyHandle"
									label="Company Handle (Instagram)"
									placeholder="IG Handle"
								/>
							</div>
						</form>
					</FormProvider>
					<Button full variant="primary" size="large" onClick={form.onSubmit}>
						Add Company to Wishlist
					</Button>
					{wishlistRequests?.length ? (
						<div className="flex flex-col gap-2">
							<div className="flex w-full items-center justify-between">
								<Heading variant="h5">Current Requests</Heading>
								<Tooltip>
									<TooltipTrigger asChild>
										<Text className="text-gray-100">
											{creditsLeft} request{creditsLeft === 1 ? " " : "s "}
											remaining
										</Text>
									</TooltipTrigger>
									<TooltipContent>
										<Text className="max-w-xs text-center">
											Your credits will renew at the start of each month
										</Text>
									</TooltipContent>
								</Tooltip>
							</div>
							<div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-6">
								<div className="flex w-full justify-between">
									<Text className="text-gray-100">Company</Text>
									<div className="flex gap-3">
										<Text className="text-gray-100">Status</Text>
										<div className="w-4" />
									</div>
								</div>
								{wishlistRequests.map((r) => (
									<div
										key={r.uuid}
										className="flex w-full items-center justify-between"
									>
										<Text variant="bold/lg">{r.companyName}</Text>
										<div className="flex items-center gap-3">
											<div className="relative flex">
												{renderWishlistStatus(
													r.status,
													r.comment,
													r.hasTemporary,
												)}
											</div>
											<button
												className="flex cursor-pointer rounded text-gray-100 transition-all duration-150 ease-in-out"
												onClick={() => deleteRequestMutation.mutate(r.uuid)}
											>
												<Delete aria-label="Delete request" />
											</button>
										</div>
									</div>
								))}
							</div>
						</div>
					) : null}
				</div>
			</ModalBody>
		</>
	);
};
