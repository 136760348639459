import { Warning } from "@withjuly/julycons";
import React from "react";
import { FiRefreshCcw } from "react-icons/fi";
import { Text, Button } from "@withjuly/solis";

export interface LoadingFailureProps {
	onRetry: () => void;
}

export const LoadingFailure: React.FC<LoadingFailureProps> = ({
	onRetry: onClick,
}) => {
	return (
		<div className="flex h-full w-full flex-col items-center">
			<Warning className="text-gray-100" />
			<Text>Something went wrong. Sorry about that!</Text>
			<Button variant="primary" onClick={onClick} right={<FiRefreshCcw />}>
				Try again
			</Button>
		</div>
	);
};
