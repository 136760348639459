import { useState } from "react";
import {
	Button,
	Modal,
	modalSizes,
	ProgressCircle,
	theme,
} from "@withjuly/solis";
import { WishlistModal } from "../../Wishlist/WishlistModal";
import {
	Close,
	Filter,
	HeartFullAdd,
	Question,
	Queue,
} from "@withjuly/julycons";
import { usePitchQueue } from "~/utils/context/pitch-queue";
import { usePruneQueue } from "~/utils/hooks/prune-queue";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { trpc } from "~/components/Utility/trpc";
import { SlideFade } from "~/components/Utility/SlideFade";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";
import { useCreator } from "~/utils/context/creator";
import { MatchSettingsModal } from "./MatchSettingsModal";
import { DiscoverInfoModal } from "./DiscoverInfoModal";
import { QueueProgressModal } from "./QueueProgressModal";
import { EmailQueueStatusSchema } from "@withjuly/fabric";
import { useLocalStorage } from "~/utils/hooks/localstorage";

export const MatchActions = () => {
	const { creatorProfile } = useCreator();
	const { brands: matches } = usePitchQueue();
	const { data: billing } = trpc.billing.get.useQuery(undefined, {
		refetchOnWindowFocus: false,
	});

	const [isWishlistOpen, setIsWishlistOpen] = useState(false);
	const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
	const [isWishlistUpgradeOpen, setIsWishlistUpgradeOpen] = useState(false);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [isInfoOpen, setIsInfoOpen] = useState(false);
	const [isIndustriesHintOpen, setIsIndustriesHintOpen] = useState(() => true);
	const [showQueueProgress, setShowQueueProgress] = useState(false);
	const { data: queues } = trpc.dealFlow.getQueuedPitches.useQuery();
	const [onBoardStep] = useLocalStorage<
		{ step: string; uuid: string } | undefined
	>("onboard", undefined);

	const activeQueue = queues?.find(
		(queue) =>
			queue.status === EmailQueueStatusSchema.enum.IN_PROGRESS ||
			queue.status === EmailQueueStatusSchema.enum.PENDING,
	);

	usePruneQueue();

	const isFreePlan = billing?.plan === "free";
	let totalPlanCredits = 5;
	if (billing?.plan === "trending") {
		totalPlanCredits = 50;
	} else if (billing?.plan === "paid") {
		totalPlanCredits = 500;
	}
	const creditsUsed = Math.max(
		totalPlanCredits - (creatorProfile?.pitchCredits ?? 0),
		0,
	);
	const creditsLeft = totalPlanCredits - creditsUsed;
	const progress = (creditsLeft / totalPlanCredits) * 100;

	const onClickWishlist = () => {
		if (isFreePlan) {
			setIsWishlistUpgradeOpen(true);
		} else {
			setIsWishlistOpen(true);
		}
	};

	return (
		<>
			<div className="flex items-center justify-end gap-3">
				<SlideFade key={(matches.length === 0).toString()}>
					<div className="flex items-center justify-center gap-4">
						<div className="hidden items-center gap-2 lg:flex">
							<ProgressCircle
								progress={progress}
								radius={8}
								stroke={2}
								color={theme.color.white[100]}
								trackColor={theme.color.gray[400]}
							/>
							<p className="text-bold-lg">{creditsLeft} credits remaining</p>
						</div>
					</div>
				</SlideFade>
				<div className="flex items-center gap-2">
					<div className="flex items-center">
						{activeQueue ? (
							<Button
								variant="icon"
								size="icon-small"
								className="animate-fade-in"
								style={{
									animationDuration: "0.25s",
								}}
								onClick={() => setShowQueueProgress(() => true)}
							>
								<Queue className="h-4 w-4 " />
							</Button>
						) : null}
						<Button
							variant="icon"
							size="icon-small"
							aria-label="Learn more about Discover"
							onClick={() => setIsInfoOpen(() => true)}
						>
							<Question className="h-4 w-4" />
						</Button>
						<div className="relative flex flex-col items-end justify-center lg:items-center">
							<Button
								variant="icon"
								size="icon-small"
								aria-label="Change match preferences"
								onClick={() => setIsSettingsOpen(() => true)}
							>
								<Filter className="h-4 w-4" />
							</Button>
							{isIndustriesHintOpen &&
							onBoardStep &&
							onBoardStep.step === "pitch" ? (
								<div className="absolute top-12 z-30">
									<div className="animate-open-fade-move-down relative flex flex h-full w-full min-w-[272px] flex-col flex-col items-end justify-center rounded-md bg-blue-300 p-4 lg:items-center">
										<div className="absolute top-0 h-3 w-3 -translate-y-[6px] rotate-45 transform transform rounded-[2px] bg-blue-300">
											{/* POINTER */}
										</div>
										<div className="flex flex-col gap-2 text-gray-900">
											<div className="flex w-full items-start justify-between">
												<h6>Set Brand Preferences</h6>
												<button
													onClick={() => setIsIndustriesHintOpen(() => false)}
												>
													<Close />
												</button>
											</div>
											<p>Decide what types of brands you want to see.</p>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
					<Button size="small" variant="secondary" onClick={onClickWishlist}>
						<div className="flex items-center gap-2">
							<p className="hidden lg:block">Request a brand</p>
							<HeartFullAdd />
						</div>
					</Button>
				</div>
			</div>

			<Modal
				isOpen={isWishlistOpen}
				setIsOpen={setIsWishlistOpen}
				size={modalSizes[480]}
				content={<WishlistModal />}
			/>

			<UpgradeModal
				isOpen={isWishlistUpgradeOpen}
				setIsOpen={setIsWishlistUpgradeOpen}
				feature="wishlist"
			/>

			<Modal
				isOpen={isConfirmDeleteOpen}
				setIsOpen={setIsConfirmDeleteOpen}
				size={modalSizes[480]}
				content={
					<ConfirmDeleteModal setIsConfirmDeleteOpen={setIsConfirmDeleteOpen} />
				}
			/>

			<Modal
				isOpen={isSettingsOpen}
				setIsOpen={setIsSettingsOpen}
				size={modalSizes[640]}
				content={<MatchSettingsModal setIsOpen={setIsSettingsOpen} />}
			/>

			<Modal
				isOpen={isInfoOpen}
				setIsOpen={setIsInfoOpen}
				size={modalSizes[640]}
				content={<DiscoverInfoModal setIsOpen={setIsInfoOpen} />}
			/>

			<Modal
				isOpen={showQueueProgress && !!activeQueue}
				setIsOpen={setShowQueueProgress}
				size={modalSizes[480]}
				content={<QueueProgressModal />}
			/>
		</>
	);
};
