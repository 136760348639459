import { cx } from "@withjuly/frontend-common";
import { Close, Pitch } from "@withjuly/julycons";
import {
	Button,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@withjuly/solis";
import { useEffect, useMemo, useState } from "react";
import { usePitchQueue } from "~/utils/context/pitch-queue";
import { trpc } from "../Utility/trpc";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { PitchModal } from "../Pitch/PitchModal/PitchModal";
import { useCreator } from "~/utils/context/creator";
import { useBilling } from "~/utils/context/billing";
import { UpgradeModal } from "../Payment/UpgradeModal";

export const QueueConsole = () => {
	const { brands, pruneQueue, setQueue, removeFromQueue } = usePitchQueue();
	const { data: allQueueableMatches } = trpc.match.queueable.useQuery();
	const [isQueueOpen, setIsQueueOpen] = useState(false);
	const [isConsoleOpen, setIsConsoleOpen] = useState(false);
	const [isPitchOpen, setIsPitchOpen] = useState(false);
	const { creatorProfile } = useCreator();
	const billing = useBilling();
	const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(() => false);

	const disabled = useMemo(() => {
		let totalPlanCredits = 5;
		if (billing?.plan === "trending") {
			totalPlanCredits = 50;
		} else if (billing?.plan === "paid") {
			totalPlanCredits = 500;
		}
		const creditsUsed = Math.max(
			totalPlanCredits - (creatorProfile?.pitchCredits ?? 0),
			0,
		);

		const creditsLeft = totalPlanCredits - creditsUsed;

		return creditsLeft < brands.length;
	}, [billing?.plan, brands.length, creatorProfile?.pitchCredits]);

	useEffect(() => {
		if (brands.length > 0 && !isConsoleOpen) {
			setIsConsoleOpen(() => true);
		} else if (brands.length === 0 && isConsoleOpen) {
			setIsConsoleOpen(() => false);
			setIsQueueOpen(() => false);
			setIsPitchOpen(() => false);
		}
	}, [brands, isConsoleOpen]);

	return (
		<>
			<AnimatePresence>
				{isConsoleOpen ? (
					<motion.div
						className={cx(
							"shadow-100 md:min-h-auto flex min-h-[70px] items-center justify-between gap-3 border border-gray-500 bg-gray-800 md:min-w-[440px] md:justify-evenly md:rounded-xl",
						)}
						initial={{
							y: 100,
							opacity: 0,
						}}
						animate={{
							y: 0,
							opacity: 1,
						}}
						exit={{
							y: 100,
							opacity: 0,
						}}
						transition={{
							ease: "anticipate",
						}}
					>
						<div className="w-full">
							<AnimatePresence>
								{isQueueOpen ? (
									<motion.div
										initial={{
											height: 0,
										}}
										animate={{
											height: "auto",
										}}
										exit={{
											height: 0,
										}}
										transition={{ ease: "easeOut", duration: 0.15 }}
										className="flex max-h-[480px] flex-col gap-4 overflow-x-hidden overflow-y-scroll border-b border-gray-500"
									>
										{brands.map((brand, i) => (
											<div
												key={`queue-${brand.company.uuid}-${i}`}
												className="flex w-full items-center justify-between pl-6 pr-3 first:pt-6 last:pb-6"
											>
												<p className="text-white-100">{brand.company.name}</p>
												<Button
													variant="icon"
													size="icon-small"
													onClick={() => removeFromQueue(brand)}
												>
													<Close />
												</Button>
											</div>
										))}
									</motion.div>
								) : null}
							</AnimatePresence>
							<div className="flex items-center justify-between gap-3 py-4 pl-6 pr-3 md:justify-evenly">
								<div className="flex flex-col items-start justify-center md:min-w-[80px]">
									<button
										onClick={() => setIsQueueOpen((prev) => !prev)}
										className="flex flex-col items-start"
									>
										<p className="text-white-100">{brands.length} in queue</p>
										<p className="text-body-sm text-gray-200">
											{isQueueOpen ? "Hide" : "Show"} list
										</p>
									</button>
								</div>
								<div className="flex items-center justify-between gap-2">
									<div className="flex items-center justify-end gap-3 md:w-[280px]">
										<Button
											variant="secondary"
											size="medium"
											className="w-[90px]"
											onClick={() => {
												if (brands.length === allQueueableMatches?.length) {
													pruneQueue([]);
													setIsQueueOpen(() => false);
												} else {
													setQueue(allQueueableMatches ?? []);
												}
											}}
										>
											{brands.length === allQueueableMatches?.length
												? "Clear all"
												: "Add all"}
										</Button>
										<Tooltip open={isUpgradeModalOpen ? false : undefined}>
											<TooltipTrigger asChild>
												<Button
													variant="primary"
													size="medium"
													onClick={() => setIsPitchOpen(() => true)}
													disabled={disabled}
												>
													<span className="flex flex-row items-center gap-2">
														<span className="md:hidden">Pitch</span>
														<span className="hidden md:block">
															Choose template
														</span>
														<Pitch />
													</span>
												</Button>
											</TooltipTrigger>
											<TooltipContent side="bottom">
												{disabled ? (
													<p className="max-w-xs text-center">
														<p className="max-w-xs text-center">
															You need more pitch credits to queue another
															brand. Please upgrade your account to{" "}
															<span
																className="text-blue-500 underline"
																role="button"
																onClick={() =>
																	setIsUpgradeModalOpen(() => true)
																}
															>
																here
															</span>
															.
														</p>
													</p>
												) : null}
											</TooltipContent>
										</Tooltip>
									</div>
									<Button
										variant="icon"
										size="icon-small"
										onClick={() => {
											pruneQueue([]);
											setIsQueueOpen(() => false);
										}}
									>
										<Close />
									</Button>
								</div>
							</div>
						</div>
					</motion.div>
				) : null}
			</AnimatePresence>
			{isPitchOpen ? (
				<PitchModal
					showStrategyOptions={brands.length === 1}
					isOpen={isPitchOpen}
					setIsOpen={setIsPitchOpen}
					brands={brands}
				/>
			) : null}
			<UpgradeModal
				isOpen={isUpgradeModalOpen}
				setIsOpen={setIsUpgradeModalOpen}
			/>
		</>
	);
};
