import { FACEBOOK, Platform } from "@withjuly/fabric";
import { Heading, Button, Modal, modalSizes } from "@withjuly/solis";
import { useState } from "react";
import { useConnectAccount } from "~/utils/hooks/oauth";
import { trpc } from "../Utility/trpc";
import Link from "next/link";
import { MatchSettingsModal } from "./MatchActions/MatchSettingsModal";

interface MatchesInfoCardProps {
	isWishlist: boolean;
	matchCount?: number;
}

export const MatchesInfoCard: React.FC<MatchesInfoCardProps> = ({
	isWishlist,
	matchCount,
}) => {
	const utils = trpc.useContext();
	const { data: profiles } = trpc.profile.getAll.useQuery();
	const [isEditOpen, setIsEditOpen] = useState(false);
	const { onConnect, isLoading } = useConnectAccount(FACEBOOK, () => {
		utils.profile.getAll.invalidate();
	});

	if (isWishlist || matchCount === undefined || !profiles) {
		return null;
	}

	const instagramProfile = profiles?.find(
		(p) => p.platform === Platform.INSTAGRAM,
	);

	if (matchCount === 0) {
		if (instagramProfile) {
			return (
				<div className="shadow-60 flex h-[25rem] flex-col justify-between rounded-xl border border-dashed border-gray-300 bg-gray-800 p-6 sm:h-[30rem]">
					<div className="flex h-full flex-col gap-1">
						<Heading variant="h3">You&apos;re out of matches</Heading>
						<p>Check back tomorrow for more brands to work with!</p>

						<Link href="/referral" className="mt-auto">
							<Button size="huge" full>
								Loving July? Refer a friend!
							</Button>
						</Link>
					</div>
				</div>
			);
		} else {
			return (
				<>
					<div className="shadow-60 flex h-[25rem] flex-col justify-between rounded-xl border border-dashed border-gray-200 p-8 sm:h-[30rem]">
						<div className="flex flex-col gap-1">
							<Heading variant="h3">No account connected</Heading>
							<p>
								You don’t have an Instagram account connected. Add an account to
								receive matches.
							</p>
						</div>
						<Button full size="large" onClick={onConnect} isLoading={isLoading}>
							Connect
						</Button>
					</div>
				</>
			);
		}
	} else if (matchCount <= 5 && instagramProfile?.isMatchPoolLow) {
		return (
			<>
				<div className="shadow-60 flex h-[25rem] flex-col justify-between rounded-xl border border-dashed border-gray-200 p-8 sm:h-[30rem]">
					<div className="flex flex-col gap-1">
						<Heading variant="h3">Low matches</Heading>
						<p>
							To continue receiving matches consider updating your brand
							preferences.
						</p>
					</div>
					<Button full size="large" onClick={() => setIsEditOpen(() => true)}>
						Update Brand Preferences
					</Button>
				</div>

				<Modal
					isOpen={isEditOpen}
					setIsOpen={setIsEditOpen}
					size={modalSizes[640]}
					content={<MatchSettingsModal setIsOpen={setIsEditOpen} />}
				/>
			</>
		);
	}

	return null;
};
