export const SkeletonBrandCard: React.FC = () => {
	return (
		<div className="shadow-40 group relative flex h-[25rem] flex-col items-start justify-end overflow-hidden rounded-2xl bg-gray-800 p-6 sm:h-[30rem]">
			<div className="mb-8 h-16 w-16 animate-pulse rounded-full bg-gray-400" />
			<div className="flex flex-col gap-[2px]">
				<div className="h-[18px] w-[96px] animate-pulse rounded-full bg-gray-400" />
				<div className="h-4 w-16 animate-pulse rounded-full bg-gray-400" />
			</div>
			<div className="flex w-full items-center justify-between">
				<div className="h-4 w-[128px] animate-pulse rounded-full bg-gray-400" />
				<div className="flex items-center gap-3">
					<div className="h-[42px] w-[42px] animate-pulse rounded-full bg-gray-400" />
					<div className="h-[42px] w-[87px] animate-pulse rounded-full bg-gray-400" />
				</div>
			</div>
		</div>
	);
};
